import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ConstKpiData, constKpiHiddenData } from '@app/const';
import { KpiData, KpiKeys, StatsOptions } from '@app/models';
import { ChartToDisplay } from '@app/models/Chart';
import { ChartProvider } from '@app/providers';
import { StatsProvider } from '@app/providers/data/stats.provider';
import { ApiService, CountingService, DateHelperService } from '@app/services';
import { CountingData } from '@app/models/';

@Component({
  selector: 'app-counting-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
})
export class KpiComponent implements OnInit, OnDestroy {
  public toolTipData = {
    salesRevenue: null,
    salesRevenueVariation: null,
    attendanceTime: null,
    attractivenessRate: null,
    entryNumber: null,
    entryData: null,
    customersGroup: null,
    billTickets: null,
    transformRate: null,
  };

  public loadingAffix = {
    salesRevenue: '€',
    attendanceTime: '',
    attractivenessRate: '%',
    entryNumber: '',
    entryData: '',
    customersGroup: '',
    billTickets: '',
    transformRate: '%',
  };
  public variationAffix = {
    salesRevenue: '€',
    attendanceTime: '',
    attractivenessRate: '%',
    entryNumber: '',
    entryData: '',
    customersGroup: '',
    billTickets: '',
    transformRate: '%',
  };

  public decimalPosition = {
    salesRevenue: 0,
    attendanceTime: 0,
    attractivenessRate: 2,
    entryNumber: 0,
    entryData: 0,
    customersGroup: 0,
    billTickets: 0,
    transformRate: 2,
  };

  public showVariation = {
    salesRevenue: true,
    attendanceTime: true,
    attractivenessRate: false,
    entryNumber: true,
    customersGroup: true,
    billTickets: true,
    transformRate: false,
  };

  public kpiData: KpiData = { ...ConstKpiData };
  public kpiDataHolder: KpiData = { ...ConstKpiData };
  public kpiHiddenData: KpiData = constKpiHiddenData;
  public debugCustomers: boolean = true;
  public showDebug: boolean = false;

  public kpiDataRaw: CountingData | any;
  public dataKeys = Object.keys(this.kpiData) as KpiKeys[];

  public allData;
  private sub;
  private options: StatsOptions;

  public financialData: boolean;
  public loading = true;
  public groupEnabled = false;
  public attractivityEnabled = false;
  private isEntryNumberToogled = false;

  public showAlternativeKpi = (key: KpiKeys) => {
    if (this.groupEnabled && key === 'transformRate') {
      return 'tooltip.' + this.kpiData[key].tooltip + 'Group';
    }
    return 'tooltip.' + this.kpiData[key].tooltip;
  };

  constructor(
    private statsProvider: StatsProvider,
    private dateHelper: DateHelperService,
    private chartProvider: ChartProvider,
    private apiService: ApiService,
    private countingService: CountingService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  public initEntry() {
    this.kpiData.entryNumber.content.first = this.kpiDataRaw['current'].customerVisit;
    this.kpiData.entryNumber.content.versus = this.kpiDataRaw['versus'].customerVisit;

    this.kpiDataHolder.entryNumber.content.first = this.kpiDataRaw['current'].customerVisit;
    this.kpiDataHolder.entryNumber.content.versus = this.kpiDataRaw['versus'].customerVisit;

    this.kpiData.entryNumber.content.percent = this.kpiDataRaw['comparison'].customerVisit.percent;
    this.kpiDataHolder.entryNumber.content.percent =
      this.kpiDataRaw['comparison'].customerVisit.percent;

    this.kpiData.entryNumber.content.fluctuation =
      this.kpiDataRaw['comparison'].customerVisit.fluctuation;
    this.kpiDataHolder.entryNumber.content.fluctuation =
      this.kpiDataRaw['comparison'].customerVisit.fluctuation;
  }

  public initEntryCustomerGroup() {
    this.kpiData.customersGroup.content.first = this.kpiDataRaw['current'].customerGroupVisit;
    this.kpiData.customersGroup.content.versus = this.kpiDataRaw['versus'].customerGroupVisit;

    this.kpiDataHolder.customersGroup.content.first = this.kpiDataRaw['current'].customerGroupVisit;
    this.kpiDataHolder.customersGroup.content.versus = this.kpiDataRaw['versus'].customerGroupVisit;

    this.kpiData.customersGroup.content.percent =
      this.kpiDataRaw['comparison'].customerGroupVisit.percent;

    this.kpiDataHolder.customersGroup.content.percent =
      this.kpiDataRaw['comparison'].customerGroupVisit.percent;

    this.kpiData.customersGroup.content.fluctuation =
      this.kpiDataRaw['comparison'].customerGroupVisit.fluctuation;
    this.kpiDataHolder.customersGroup.content.fluctuation =
      this.kpiDataRaw['comparison'].customerGroupVisit.fluctuation;
  }

  public initAttractivenessRate() {
    this.kpiData.attractivenessRate.content.first = this.kpiDataRaw['current'].attractivity;
    this.kpiData.attractivenessRate.content.versus = this.kpiDataRaw['versus'].attractivity;
    this.kpiData.attractivenessRate.content.percent = this.kpiDataRaw['comparison'].attractivity;
  }

  public initAttendanceTime() {
    this.kpiData.attendanceTime.content.first = this.kpiDataRaw['current'].timePassed;
    this.kpiData.attendanceTime.content.versus = this.kpiDataRaw['versus'].timePassed;
    this.kpiData.attendanceTime.content.percent = this.kpiDataRaw['comparison'].timePassed.percent;
    this.kpiData.attendanceTime.content.fluctuation =
      this.kpiDataRaw['comparison'].timePassed.fluctuation;
  }

  public initSales() {
    this.kpiData.salesRevenue.content.first = this.kpiDataRaw['current'].ca;
    this.kpiData.salesRevenue.content.versus = this.kpiDataRaw['versus'].ca;
    this.kpiData.salesRevenue.content.percent = this.kpiDataRaw['comparison'].ca.percent;
    this.kpiData.salesRevenue.content.fluctuation = this.kpiDataRaw['comparison'].ca.fluctuation;

    this.toolTipData['salesRevenue'] = {
      first: this.kpiDataRaw['current'].ca,
      versus: this.kpiDataRaw['versus'].ca,
    };
  }

  public initBill() {
    this.kpiData.billTickets.content.first = this.kpiDataRaw['current'].ticket;
    this.kpiData.billTickets.content.versus = this.kpiDataRaw['versus'].ticket;
    this.kpiData.billTickets.content.percent = this.kpiDataRaw['comparison'].ticket.percent;
    this.kpiData.billTickets.content.fluctuation = this.kpiDataRaw['comparison'].ticket.fluctuation;
  }

  public initTransform() {
    this.kpiData.transformRate.content.first = this.kpiDataRaw['current'].transformRate;
    this.kpiData.transformRate.content.versus = this.kpiDataRaw['versus'].transformRate;
    this.kpiData.transformRate.content.percent = this.kpiDataRaw['comparison'].transformRate;
  }

  public checkKpiAlerts() {
    this.kpiData.entryNumber.content.showAlert = this.kpiData.entryNumber.content.percent < 0;
    this.kpiDataHolder.entryNumber.content.showAlert = this.kpiData.entryNumber.content.percent < 0;

    this.kpiData.customersGroup.content.showAlert = this.kpiData.customersGroup.content.percent < 0;
    this.kpiDataHolder.customersGroup.content.showAlert =
      this.kpiData.customersGroup.content.percent < 0;

    this.kpiData.attractivenessRate.content.showAlert =
      this.kpiData.attractivenessRate.content.percent < 0;

    // this.kpiData.attendanceTime.content.showAlert = this.kpiData.attendanceTime.content.percent < 0;

    this.kpiData.salesRevenue.content.showAlert = this.kpiData.salesRevenue.content.percent < 0;

    this.kpiData.transformRate.content.showAlert = this.kpiData.transformRate.content.percent < 0;

    this.kpiData.billTickets.content.showAlert = this.kpiData.billTickets.content.percent < 0;
  }

  ngOnInit(): void {
    this.sub = this.statsProvider.getOptions().subscribe((options) => {
      this.loading = true;
      this.options = options;
      this.kpiData = { ...ConstKpiData };
      this.groupEnabled = options.targetData.groupsCountingEnabled;
      this.attractivityEnabled = options.targetData.attractivityEnabled;

      if (options.type) {
        this.loading = true;
        const begin = options.range.begin;
        const beginVs = options.range.beginVs;
        const end = options.range.end;
        const endVs = options.range.endVs;

        this.apiService.business
          .getBusinessDetail(options.targetData.id)
          .subscribe((businessSettings) => {
            const range = this.dateHelper.rangeToUnixTimestampWithCheck(
              { begin, end },
              { begin: beginVs, end: endVs },
              businessSettings,
            );

            this.countingService
              .getKpiData(options.targetData.id, options.rangeType, {
                begin: range.begin,
                end: range.end,
                beginVs: range.beginVs,
                endVs: range.endVs,
              })
              .subscribe((value) => {
                this.kpiDataRaw = value;
                this.financialData = !!value.localBusiness;

                this.cleanEntryData();
                this.initEntry();
                this.initEntryCustomerGroup();
                this.initAttractivenessRate();
                // this.initAttendanceTime();
                this.initSales();
                this.initTransform();
                this.initBill();

                setTimeout(() => {
                  this.checkKpiAlerts();
                  this.loading = false;
                });
                console.log('kpiData', this.kpiData);
              });
          });
      }
    });
  }

  public putFirstChartLayout(value: ChartToDisplay): void {
    setTimeout(() => {
      this.chartProvider.setLayout(value);
    }, 0);
  }

  cleanEntryData() {
    this.isEntryNumberToogled = false;
    this.kpiData.entryNumber.icon = 'EntryNumberBlack';
    this.kpiData.entryNumber.iconSwitchOn = 'customerGroupBlack';
    this.kpiData.entryNumber.title = 'entryNumber';
    this.kpiData.entryNumber.tooltip = 'entryNumber';
    this.kpiData.entryNumber.switchOn = 'CustomersGroup';
    this.kpiData.entryNumber.disabled = false;
    this.debugCustomers = true;
  }

  public refresh() {
    this.loading = true;
    this.cleanEntryData();
    // this.initAttendanceTime();
    this.initAttractivenessRate();
    this.initBill();
    this.initEntry();
    this.initEntryCustomerGroup();
    this.initSales();
    this.initTransform();

    setTimeout(() => {
      this.checkKpiAlerts();
      this.loading = false;
      this.changeDetector.detectChanges();
    });
  }

  // public showEntryGroup() {
  //   this.kpiData.entryNumber.content.first = this.kpiDataRaw['current'].customerGroupVisit;
  //   this.kpiData.entryNumber.content.versus = this.kpiDataRaw['versus'].customerGroupVisit;
  //   this.kpiData.entryNumber.content.percent =
  //     this.kpiDataRaw['comparison'].customerGroupVisit.percent;
  //   this.kpiData.entryNumber.content.fluctuation =
  //     this.kpiDataRaw['comparison'].customerGroupVisit.fluctuation;

  //   this.kpiData.entryNumber.content.showAlert = this.kpiData.entryNumber.content.percent < 0;
  //   this.kpiData.entryNumber.disabled = this.kpiHiddenData.customersGroup?.disabled;
  //   this.kpiData.entryNumber.icon = 'customerGroupBlack';
  //   this.kpiData.entryNumber.iconSwitchOn = 'EntryNumberBlack';
  //   this.kpiData.entryNumber.title = 'customersGroup';
  //   this.kpiData.entryNumber.tooltip = 'customersGroup';
  //   this.kpiData.entryNumber.switchOn = 'EntryNumber';
  //   this.isEntryNumberToogled = true;
  // }

  // public showEntryCustomer() {
  //   this.kpiData.entryNumber.content.first = this.kpiDataRaw['current'].customerVisit;
  //   this.kpiData.entryNumber.content.versus = this.kpiDataRaw['versus'].customerVisit;
  //   this.kpiData.entryNumber.content.percent = this.kpiDataRaw['comparison'].customerVisit.percent;
  //   this.kpiData.entryNumber.content.fluctuation =
  //     this.kpiDataRaw['comparison'].customerVisit.fluctuation;

  //   this.kpiData.entryNumber.content.showAlert = this.kpiData.entryNumber.content.percent < 0;
  //   this.kpiData.entryNumber.disabled = false;
  //   this.kpiData.entryNumber.icon = 'EntryNumberBlack';
  //   this.kpiData.entryNumber.iconSwitchOn = 'customerGroupBlack';
  //   this.kpiData.entryNumber.title = 'entryNumber';
  //   this.kpiData.entryNumber.tooltip = 'entryNumber';
  //   this.kpiData.entryNumber.switchOn = 'CustomersGroup';
  //   this.isEntryNumberToogled = false;
  // }

  // switchOnEntryData() {
  //   if (!this.isEntryNumberToogled) this.showEntryGroup();
  //   else this.showEntryCustomer();

  //   this.changeDetector.detectChanges();
  // }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
