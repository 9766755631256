/* tslint:disable */
import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';

@Pipe({
  name: 'arrayFilter',
})
export class arrayFilterPipe implements PipeTransform {
  transform(data: { [key: string]: string }[], term: any, field: string, array?: any): any {
    if (!data) return '';

    if (array !== undefined) {
      if (term === undefined) return array;

      return array.filter(function (activity) {
        return activity[field].toLowerCase().includes(term.toLowerCase());
      });
    }
    if (term === undefined) return data;

    return data.filter(function (activity) {
      return activity[field]?.toLowerCase()?.includes(term.toLowerCase());
    });
  }
}

@Pipe({
  name: 'kpiFilter',
})
export class kpiFilter implements PipeTransform {
  transform(
    data: any,
    title: string,
    affix: string,
    limit?: number,
    decimalPosition?: number,
  ): any {
    if (title == 'salesRevenue' || title == 'entryNumber' || title == 'customersGroup') {
      if (title == 'entryNumber' || title == 'customersGroup') {
        limit = 5000;
      }
      if (isNaN(data)) return customNumberFilter(0, decimalPosition) + ' ' + affix;
      if (data === null) return customNumberFilter(0, decimalPosition) + ' ' + affix;
      const value = Number(data);
      if (value === 0) return customNumberFilter(value, decimalPosition) + ' ' + affix;
      if (value <= (limit || 999) && value > 0)
        return customNumberFilter(value, decimalPosition) + ' ' + affix;
      let abs = Math.abs(value);
      const rounder = Math.pow(10, 1);
      const isNegative = value < 0;
      let key = '';

      const powers = [
        { key: 'Q', value: Math.pow(10, 15) },
        { key: 'T', value: Math.pow(10, 12) },
        { key: 'B', value: Math.pow(10, 9) },
        { key: 'M', value: Math.pow(10, 6) },
        { key: 'K', value: 1000 },
      ];

      for (let i = 0; i < powers.length; i++) {
        let reduced = abs / powers[i].value;
        reduced = Math.round(reduced * rounder) / rounder;
        if (reduced >= 1) {
          abs = reduced;
          key = powers[i].key;
          break;
        }
      }

      return (isNegative ? '-' : '') + abs + key + ' ' + affix;
    } else if (
      title !== 'salesRevenue' &&
      data &&
      title !== 'attendanceTime' &&
      title !== 'salesRevenueFilter'
    ) {
      return customNumberFilter(data, decimalPosition) + ' ' + affix;
    } else if (data) {
      return data + ' ' + affix;
    } else {
      return 0 + ' ' + affix;
    }
  }
}

@Pipe({
  name: 'customNumber',
})
export class CustomNumberFilter implements PipeTransform {
  transform(number: any, decimal_pos?: any, decimal_sep?: any, thousand_sep?: any): any {
    return customNumberFilter(number, decimal_pos, decimal_sep, thousand_sep);
  }
}

export const customNumberFilter = (
  number: any,
  decimal_pos?: any,
  decimal_sep?: any,
  thousand_sep?: any,
) => {
  const _number = Number((number + '').replace(',', '.').replace(/[^0-9.-]/g, ''));
  if (isNullOrUndefined(_number) || isNaN(_number) || !isFinite(_number)) {
    return decimal_pos ? '0,' + parseFloat('0').toFixed(decimal_pos).split('.')[1] : '0';
  }
  var dp = decimal_pos == null ? 2 : decimal_pos,
    regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${dp}})?`),
    f = Number((_number + '').match(regex)[0]),
    t = f.toLocaleString('FR-fr');
  return t;
};

@Pipe({
  name: 'limitPercentage',
})
export class LimitePercentage implements PipeTransform {
  transform(value: number, affix: string) {
    if (isNaN(value)) return '0 %';
    if (value < -100) return '-100 %';
    if (value > 100) return '100 %';

    return value + ' ' + affix;
  }
}
